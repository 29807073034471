export const zStringMediumLengthType = z.string().min(1).max(50)
export const zStringLongLengthType = z.string().min(1).max(100)
export const zStringShortLengthType = z.string().min(1).max(20)
export const zStringPostCodeType = z.string().min(1).max(10)
export const zStringDialCodeType = z.string().min(1)
export const zStringCountryIdType = z.string().min(1)
export const zStringPhoneType = z.string().min(1).max(30)
export const zStringMailType = z.string().min(1).max(74).email()
export const zStringNoteType = z.string().max(250)
export const zStringPassword = zStringLongLengthType.regex(/[A-Z]/).regex(/[a-z]/).regex(/[0-9]/)

